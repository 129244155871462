import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import Rating from 'common/components/Rating';
import Link from 'common/components/Link';
import { Icon } from 'react-icons-kit';
import GlideCarousel from 'common/components/GlideCarousel';
import GlideSlide from 'common/components/GlideCarousel/glideSlide';
import { openModal, closeModal } from '@redq/reuse-modal';
import { playCircle } from 'react-icons-kit/fa/playCircle';
import { SectionHeader } from '../appClassic.style';
import TagManager from 'react-gtm-module'
import SectionWrapper, { CarouselWrapper,  VideoWrapper, } from './testimonial.style';

const tagManagerArgs = {
  gtmId: 'GTM-P3PVRNQ',
  dataLayer: {
      page: 'Testimonial Page',
      event: 'watch_review'
  }
}

const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);


const Testimonial = () => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        testimonial {
          slogan
          title
          reviews {
            id
            title
            description
            avatar
            name
            designation
            review
          }
        }
      }
    }
  `);
  const ModalContent = () => (
    <VideoWrapper>
      <iframe
        title="App Demo"
        src= "https://h2zero-logging-1234.s3.amazonaws.com/1.mov"
        frameBorder="0"
      />
    </VideoWrapper>
  );

  const handleVideoModal = () => {
    openModal({
      config: {
        html: { useGatsbyImage: false },
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: 'auto',
          height: 'auto',
          x: 0,
          y: 0,
        },
      },
      withRnd: false,
      overlayClassName: 'customeOverlayClass',
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
    TagManager.initialize(tagManagerArgs)
  };

  const { slogan, title, reviews } = data.appClassicJson.testimonial;

  const glideOptions = {
    type: 'carousel',
    gap: 0,
    autoplay: 5000,
    perView: 2,
    animationDuration: 700,
    breakpoints: {
      991: {
        perView: 1,
      },
    },
  };

  return (
    <SectionWrapper id="testimonial">
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading as="h5" content={slogan} />
            <Heading content={title} />
          </Fade>
        </SectionHeader>
        <CarouselWrapper>
          <Fade up delay={100}>
            <GlideCarousel
              options={glideOptions}
              nextButton={
                <Button
                  icon={<i className="flaticon-next" />}
                  aria-label="Next"
                  variant="fab"
                />
              }
              prevButton={
                <Button
                  icon={<i className="flaticon-left-arrow" />}
                  aria-label="Prev"
                  variant="fab"
                />
              }
            >
              <Fragment>
                {reviews.map((item) => (
                  <GlideSlide key={`testimonial--key${item.id}`}>
                    <div className="review-card">
                      <Heading as="h3" content={item.title} />
                      <Text content={item.description} />
                      <div className="card-footer">
                        <div className="image">
                          <Image src={item.avatar} alt="Client Image" />
                        </div>
                        <div className="reviewer-info">
                          <div className="content">
                            <Heading as="h4" content={item.name} />
                            <Text content={item.designation} />
                            <Link
                             content={"Click Me"}
                             icon={<Icon icon={playCircle} />}
                             onClick={handleVideoModal} />
                          </div>
                          <Rating rating={item.review} />
                        </div>
                      </div>
                    </div>
                  </GlideSlide>
                ))}
              </Fragment>
            </GlideCarousel>
          </Fade>
        </CarouselWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Testimonial;
