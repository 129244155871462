import React, { useState, useEffect } from 'react';
import TagManager from 'react-gtm-module'

function FlashingButton() {
  const [isFlashing, setIsFlashing] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFlashing((prevIsFlashing) => !prevIsFlashing);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const buttonColor = isFlashing ? '#005dad' : '#0075D9';

  const tagManagerArgs = {
    gtmId: 'GTM-P3PVRNQ',
    dataLayer: {
        page: 'Flashing Button',
        event: 'Book Appointment' //book_appointment
    }
  }

  return (
    <button className="flashing button"
            style={{ backgroundColor: buttonColor,
                     paddingVertical: 12,
                     paddingHorizontal: 32,
                     borderRadius: 6,
                     elevation: 20,
                     height: 50,
                     cursor: 'pointer',
                     shadowColor: '#171717',
                     color: '#FFF',
                     shadowOffset: {width: -2, height: 4},
                     shadowOpacity: 0.2,
                     shadowRadius: 3,
                     fontWeight: 600,
                     fontSize: 15,
                    }}
                     onClick={() => {
                      window.location.href="https://calendly.com/h2zero";
                      TagManager.initialize(tagManagerArgs)
                       }}>
                        Book Your Tank Evaluation / Water Testing
                        </button>
  );
}

export default FlashingButton;