import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Image from 'common/components/Image';
import { PopupWidget } from 'react-calendly'
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import FlashingButton from 'common/components/FlashingButton';
import Button from 'common/components/Button';
import { openModal, closeModal } from '@redq/reuse-modal';
import JoinTrailArea, { ContentWrapper, ButtonWrap, CalWrapper } from './joinTrail.style';

const JoinTrail = () => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        joinSlack {
          logo {
            publicURL
          }
          title
          description
        }
      }
    }
  `);
  const { logo, title, description } = data.appClassicJson.joinSlack;
  const CloseModalButton = () => (
    <Button
      className="modalCloseBtn"
      variant="fab"
      onClick={() => closeModal()}
      icon={<i className="flaticon-plus-symbol" />}
    />
  );
  const ModalCal = () => (
    <CalWrapper>
      <iframe
        title="Calendly"
        src="https://calendly.com/h2zero"
        frameBorder="0"
      />
    </CalWrapper>
  );

  const calModal = () => {
    openModal({
      config: {
        html: { useGatsbyImage: false },
        className: 'customModal',
        disableDragging: true,
        enableResizing: {
          bottom: true,
          bottomLeft: true,
          bottomRight: true,
          left: true,
          right: true,
          top: true,
          topLeft: true,
          topRight: true,
        },
        width: 480,
        height: 650,
      },
      withRnd: false,
      overlayClassName: 'customeOverlayClass',
      component: ModalCal,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  return (
    <JoinTrailArea id="trail">

      <ContentWrapper>
       {/* <Fade up>
          <Image src={logo.publicURL} alt="Slack" />
        </Fade> */}
        <Fade up delay={100}>
          <Heading content={title} />
        </Fade>
        <Fade up delay={200}>
          <Text content={description} />

          <ButtonWrap>
          <FlashingButton></FlashingButton>
							
			</ButtonWrap>
        </Fade>
        {/*<Fade up delay={300}>
          <ButtonGroup>
            <Button title="Join with Slack" />
            <Button title="Login with Email" variant="textButton" />
          </ButtonGroup>
      </Fade> */}

      </ContentWrapper>
    </JoinTrailArea>
  );
};

export default JoinTrail;
