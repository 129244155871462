import { closeModal, openModal } from "@redq/reuse-modal";
import Button from "common/components/Button";
import FlashingButton from "common/components/FlashingButton";
import GatsbyImage from "common/components/GatsbyImage";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import Container from "common/components/UI/Container";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import TagManager from "react-gtm-module";
import { Icon } from "react-icons-kit";
import { playCircle } from "react-icons-kit/fa/playCircle";
import Fade from "react-reveal/Fade";
import BannerWrapper, {
  BannerContent,
  BannerImage,
  ButtonGroup,
  CalWrapper,
  VideoWrapper,
} from "./banner.style";

const tagManagerArgs = {
  gtmId: "GTM-P3PVRNQ",
  dataLayer: {
    page: "Banner Page",
    event: "Watch Demo", //watch_demo
  },
};

// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="App Demo"
      src="https://www.youtube.com/embed/1Or8xO02A7M"
      frameBorder="0"
    />
  </VideoWrapper>
);

const ModalCal = () => (
  <CalWrapper>
    <iframe
      title="Calendly"
      src="https://calendly.com/h2zero"
      frameBorder="0"
    />
  </CalWrapper>
);

const Banner = () => {
  const Data = useStaticQuery(graphql`
    query {
      appScreenshot: file(
        relativePath: { eq: "image/appClassic/bannerImg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 459
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  //const { client } = Data.appModernJson;
  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        html: { useGatsbyImage: false },
        className: "video-modal",
        disableDragging: true,
        default: {
          width: "auto",
          height: "auto",
          x: 0,
          y: 0,
        },
      },
      withRnd: false,
      overlayClassName: "customeOverlayClass",
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
    TagManager.initialize(tagManagerArgs);
  };

  const calModal = () => {
    openModal({
      config: {
        html: { useGatsbyImage: false },
        className: "customModal",
        disableDragging: true,
        default: {
          width: "auto",
          height: "auto",
          x: 0,
          y: 0,
        },
      },
      withRnd: false,
      overlayClassName: "customeOverlayClass",
      component: ModalCal,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="Tank Water Monitoring
              at your finger tips!"
            />
          </Fade>
          <Fade up delay={200}>
            <Text content="Get peace of mind about your water supply. In 5 mins book an appointment below. If you do not love it, we don't deserve your business.  " />
          </Fade>

          <Fade up delay={300}>
            <ButtonGroup>
              {/*<Button className="primary" title="Water Testing" onClick={() => window.location.href="https://calendly.com/h2zero/watertesting"} />*/}

              <Button
                className="text"
                variant="textButton"
                icon={<Icon icon={playCircle} />}
                title="Watch App Demo"
                onClick={handleVideoModal}
                iconPosition="left"
              />
              <Fade up delay={300}>
                <Text content="Book us now" />
              </Fade>
              <FlashingButton />
            </ButtonGroup>
          </Fade>
        </BannerContent>
        <BannerImage>
          <Fade up delay={100}>
            <GatsbyImage
              src={
                (Data.appScreenshot !== null) | undefined
                  ? Data.appScreenshot.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="App Screenshot"
            />
          </Fade>
        </BannerImage>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
