import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const JoinTrailArea = styled.div`
  width: 100%;
  padding: 75px 0;
  overflow: hidden;
  @media only screen and (max-width: 1366px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 667px) {
    padding: 45px 20px;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 460px;
  margin: 0 auto;
  text-align: center;

  img {
    display: inline-block;
    margin-bottom: 35px;
    @media only screen and (max-width: 667px) {
      margin-bottom: 27px;
      max-width: 50%;
    }
  }

  h2 {
    font-size: 48px;
    line-height: 60px;
    font-weight: 500;
    letter-spacing: -2px;
    margin-bottom: 30px;
    @media only screen and (max-width: 1366px) {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -1px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 667px) {
      font-size: 28px;
      line-height: 38px;
      letter-spacing: -0.5px;
      margin-bottom: 15px;
      padding: 0 15px;
    }
  }

  p {
    font-size: 16px;
    line-height: 33px;
    color: ${themeGet('colors.textColor', 'rgba(52, 61, 72, 0.8)')};
    @media only screen and (max-width: 667px) {
      line-height: 28px;
    }
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 50px;
  @media only screen and (max-width: 1366px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 667px) {
    margin-top: 30px;
  }
  .reusecore__button {
    border-radius: 5px;
    text-transform: inherit;
    margin-right: 20px;
    &:first-child {
      &:hover {
        background-color: ${themeGet('colors.primaryHover', '#3C74FF')};
      }
    }
    &:last-child {
      margin-right: 0;
      color: ${themeGet('colors.headingColor', '#0F2137')};
    }
  }
`;
export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
  button {
    color: ${themeGet('colors.white')};
    margin: 15px 0;
    font-size: 15px;
    line-height: 1.2em;
    font-weight: 700;
    border-radius: 7px;
    padding: 18px 20px;
    letter-spacing: -0.2px;
    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
    }
    &:hover {
      box-shadow: 0px 9px 20px -5px rgba(108, 36, 126, 0.57);
    }
  }
`;

export const CalWrapper = styled.div`
  max-width: 100%;
  position: relative;
  width: 900px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100;
    left: 100;
  }
`;

export default JoinTrailArea;
